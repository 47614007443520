<template lang="pug">
  section.slice-images-grid.my-70.md_my-100
    .-mb-30.md_-mb-40.flex.flex-wrap.justify-center.px-20
      //- images...
      figure.w-full.md_w-5x12.md_px-20.mb-30.md_mb-40(v-for="image in slice.images")
        .relative.group
          resp-img.relative(:class="[padding]", :bg="true", :image="image")
          image-hover-caption(:image="image")

</template>

<script>
export default {
  name: 'SliceImagesGrid',
  props: {
    slice: Object
  },
  computed: {
    padding () {
      return {
        'pb-ar-3x4': this.slice.aspectRatio === 'aspectRatio_3_4',
        'pb-ar-4x3': this.slice.aspectRatio === 'aspectRatio_4_3'
      }
    }
  }
}
</script>

<style>
</style>
