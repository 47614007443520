<template lang="pug">
  observer.related-posts(v-if="entries.length", :threshold="0.01", @visible="onVisible", @hidden="onHidden")
    //- (pink header)
    header.hidden.md_flex.relative.z-40.blend-multiply.pointer-events-none.bg-pink.h-125.items-center.justify-center.md_justify-end.px-20
      h3.text-20.xl_text-24.px-20.md_w-10x12 Related Posts

    container-panel-active
      .flex.flex-wrap.entries-small-alternate
        //- entries...
        programme-entry-thumb.w-full.sm_w-1x2(v-for="(entry, i) in entries", :entry="entry", size="small", :dates="false", :key="i")
</template>

<script>
import ProgrammeEntryThumb from '@/components/programme/ProgrammeEntryThumb'
export default {
  name: 'RelatedPosts',
  props: {
    entries: { type: Array, default: () => ([]) }
  },
  methods: {
    onVisible () {
      this.$root.$emit('relatedPostsVisible')
    },
    onHidden () {
      this.$root.$emit('relatedPostsHidden')
    }
  },
  components: { ProgrammeEntryThumb }
}
</script>

<style>
</style>
