<template lang="pug">
  figure.slice-image-accent-square.w-1x2.md_w-3x12.relative(:class="margin")
    image-video-clip.pb-ar-1x1.bg-gray-100(:media="slice.image[0]", :bg="true")
</template>

<script>
export default {
  name: 'SliceImageAccentSquare',
  props: {
    slice: Object
  },
  computed: {
    margin () {
      return {
        'ml-0 md_ml-3x12': this.slice.align === 'left',
        'ml-auto md_ml-6x12': this.slice.align === 'right'
      }
    }
  }
}
</script>

<style>
</style>
