<template lang="pug">
  figure.slice-project-image.my-70.md_my-100.px-20
    //- pre {{ slice}}
    project-image(:image="slice.image[0]")
</template>

<script>
import ProjectImage from '@/components/project/ProjectImage'
export default {
  name: 'SliceProjectImage',
  props: { slice: Object },
  components: { ProjectImage }
}
</script>

<style>
</style>
