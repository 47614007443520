<template lang="pug">
  ul.slice-board-list.text-16.md_text-20.xl_text-24.md_px-20.my-50.md_my-70
    .md_w-6x12.mx-auto.px-20.text-columns-2.text-column-gap-40.md_text-column-gap-20
      li(v-for="item in slice.list") {{ item.name }}
</template>

<script>
export default {
  name: 'SliceBoardList',
  props: {
    slice: Object
  }
}
</script>

<style>
</style>
