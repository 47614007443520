<template lang="pug">
  //- (no margin / for performance body)
  .slice-image-video-clip.w-full
    //- image or video component?
    image-video-clip.w-full(:media="slice.image && slice.image[0]", :bg="false", :lazyVideo="false")
</template>

<script>
export default {
  name: 'SliceImageVideoClip',
  props: {
    slice: Object
  }
}
</script>

<style>
</style>
