<template lang="pug">
  section.slice-topics.bg-yellow.pb-20.md_pt-20.md_pb-80.flex.flex-wrap.px-20(v-if="slice && slice.topics.length")
    //- topics...
    section.my-50.md_mt-60(v-for="(topic, i) in slice.topics", :class="{'w-full md_w-1x2': format === 'grid', 'w-full md_flex': format === 'image'}")
      //- mobile image
      template(v-if='windowWidth <= mobile')
        resp-img.w-full.pb-ar-1x1.relative(
          :image="topic.image[0]"
          fit="md_object-contain object-center"
        ).mb-24
      //- text column
      .flex.w-full(:class="{'md_w-1x2': format === 'image', 'justify-end': format === 'image' || i % 2 === 0, 'justify-center': format === 'grid' && i % 2 === 1}")
        .md_w-2x3.md_px-20
          //- heading
          header(v-html="topic.heading")
          //- text
          .mt-25.md_mt-40.textbody.text-14(v-html="topic.text")

          //- (footer links)
          ul.flex.mt-40(v-if="topic.footerLinks.length")
            //- links...
            template(v-for="item in topic.footerLinks")
              //- (type: link with text)
              li.w-full.flex.items-start(v-if="item.type.includes('linkWithText')")
                flex-link.mr-45.btn-theme-underline.text-10(:link="item.flexLink") {{ item.flexLink.text }}
                div.flex-1.textbody(v-html="item.text")

              //- (type: box link)
              li.mr-20(v-else-if="item.type.includes('boxLink')")
                flex-link(:link="item.flexLink")
                  btn-boxed {{ item.flexLink.text }}

      //- (image)
      .w-full.md_w-1x2(v-if="format === 'image' && windowWidth > mobile")
        figure.md_w-2x3.mx-auto.md_px-20(v-if="topic.image[0]")
          resp-img.w-full.md_pb-ar-1x1.relative(:bg="!is('md')", :image="topic.image[0]", fit="md_object-contain object-center")

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SliceTopics',
  data: () => ({
    windowWidth: 0,
    mobile: 768
  }),
  props: {
    slice: Object
  },
  computed: {
    ...mapGetters(['is']),
    format () {
      // if one topic has an image...
      return this.slice.topics.find(topic => topic.image[0]) ? 'image' : 'grid'
    }
  },
  mounted () {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', this.handleResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style>
.slice-topics{
  & header {
    /* default / h6 */
    @apply text-18;
    /* h5 larger + italic = small  ("with") */
    & h5 {
      @apply text-22;
      & em {
        @apply text-14 not-italic
      }
    }
  }
}

@media (min-width:900px) {
  .slice-topics{
    & h5{
      @apply text-24;
      & {
        em {
          @apply text-18
        }
      }
    }
  }
}
</style>
