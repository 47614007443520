<template lang="pug">
  section.slice-textbody-image.md_flex(:class="{'flex-row-reverse': slice.layout === 'textImage'}")
    //- image
    figure.md_w-1x2.order-first.md_order-none
      .pb-ar-1x1.bg-gray-50.relative
        image-video-clip(:bg="true", :media="slice.image[0]", :muteBtn="true")

    //- text
    .md_w-1x2.p-20.py-40.md_p-20
      .md_p-20.md_w-5x6.text-18.textbody(v-html="slice.text")

</template>

<script>
export default {
  name: 'SliceTextbodyImage',
  props: {
    slice: Object
  }
}
</script>

<style>
.slice-textbody-image {
  & blockquote {
    font-size: 1.333em;
  }
}
</style>
