<template lang="pug">
  //- pink row (standard pink cta size)
  .slice-script-toggle.bg-pink.h-130.md_min-h-16vw.relative.px-20.text-center.flex.items-center.justify-center.px-20.text-18.xl_text-24
    //- inner container
    .flex.w-full.justify-between.items-center.md_w-6x12.md_px-20
      //- title
      .mr-25.text-left {{ slice.label }}
      //- toggle
      button.border.border-black.rounded-full.text-10.xl_text-12.flex.items-center.p-px.w-70.xl_w-85(@click="toggle", :class="{'bg-white': scriptEl}")
        //- (on label)
        .flex-1.text-center.pl-4(v-show="scriptEl") On
        //- knob
        .bg-black.rounded-full.h-20.w-20.xl_w-25.xl_h-25
        //- (off label)
        .flex-1.text-center.pr-4(v-show="!scriptEl") Off

</template>

<script>
export default {
  name: 'ScriptToggle',
  props: {
    slice: Object
  },
  data () {
    return {
      scriptEl: null
    }
  },
  methods: {
    toggle () {
      return this.scriptEl ? this.removeScript() : this.loadScript()
    },
    loadScript () {
      const url = this.slice.script[0] && this.slice.script[0].url
      if (url) {
        this.scriptEl = document.createElement('script')
        this.scriptEl.src = url
        document.body.appendChild(this.scriptEl)
      }
    },
    removeScript () {
      if (this.scriptEl) {
        // script has destory method?
        const destroyMethod = this.slice.scriptDestroyMethod
        if (typeof window[destroyMethod] === 'function') {
          window[destroyMethod]()
        }
        // remove the <script> tag
        this.scriptEl.remove()
        // reset
        this.scriptEl = null
      }
    }
  }
}
</script>

<style>
</style>
