<template lang="pug">
  figure.slice-vimeo-youtube-player.w-full.md_px-20.my-100
    .lg_w-8x12.mx-auto.md_px-20
      .md_w-full_80.md_-ml-40
        video-plyr-js(:embed="slice.vimeoYoutubeEmbed", :video="slice.videoAsset && slice.videoAsset[0]")
</template>

<script>
export default {
  name: 'SliceVimeoYoutubePlayer',
  props: {
    slice: Object
  }
}
</script>

<style>
</style>
