<template lang="pug">
animate-push-on-panel.slice-body-text.my-70.text-14.md_text-16.xl_text-18.md_px-20.flex.flex-wrap.justify-start.items-start.bold-is-teal
  .w-full.md_w-3x12.px-20.textbody.spacer
  .w-full.px-20.md_w-6x12.textbody(v-html='slice.embedCode')
</template>

<script>
export default {
  name: 'SliceEmbed',
  props: {
    slice: Object
  }
}
</script>

<style>

</style>
