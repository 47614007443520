<template lang="pug">
  .project-landing.relative(:class="{'md_h-screen_-80': isImgCover}")
    animate-push-on-panel.w-full.md_flex.justify-between.mb-50.lg_-mb-30(:class="{'md_absolute z-10 top-0 left-0': media, 'text-black md_text-white': doc.headingColor === 'white'}")
      title-rich.text-24.md_text-30.xl_text-34.pt-40.pl-20.md_pl-40.transition.duration-200(:class="{'opacity-0 pointer-events-none': playing}")
        h1(v-html="doc.titleRich")
        //-
        div(v-if="datesVisible")
          span.text-18.md_text-20.xl_text-24
            template(v-if="doc.dateCustomText") {{ doc.dateCustomText }}
            template(v-else) {{ dateSpan(doc.dateStart, doc.dateEnd) }}

      //- tags
      ul.flex.px-20.pt-20.md_pt-45.md_px-40(v-if="doc && doc.tags && doc.tags.length")
        li.mr-8.md_mr-0.md_ml-15(v-for="tag in doc.tags")
          router-link(:to="{name: 'programme-tagged', params: {tag: tag.slug}}")
            btn-boxed.capitalize(hoverRound="1") {{ tag.title }}

    //- media
    template(v-if="media")
      template(v-if="media.type.includes('Player')")
        video-plyr-js(:embed="media.vimeoYoutubeEmbed", :video="media.videoAsset && media.videoAsset[0]", @playing="playing = true", @pause="playing = false")

      template(v-else-if="media.type.includes('heroImage')")
        image-video-clip(:media="media.image[0]", :lazyVideo="false", :bg="bkpt === 'md' && isImgCover")

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProjectLanding',
  props: {
    doc: Object
  },
  data () {
    return {
      playing: false
    }
  },
  computed: {
    ...mapGetters(['dateSpan', 'bkpt']),
    media () {
      return this.doc.projectLanding[0]
    },
    isImgCover () {
      return this.media?.image && this.media.image[0]?.kind === 'image' // && this.media.type.includes('heroImage')
    },
    datesVisible () {
      return this.doc?.dateCustomText !== '0'
    }
  }
}
</script>

<style>
</style>
