<template lang="pug">
  section.slice-audio-player.bg-pink.h-310.relative.px-20.flex.items-center
    .w-full.lg_w-8x12.mx-auto.px-20
      header.flex.items-start.mb-60
        .text-10.xl_text-12.mr-30.whitespace-no-wrap.leading-none(v-if="slice.label", style="margin-top:1em") {{ slice.label }}
        .text-20.xl_text-24(v-if="slice.heading") {{ slice.heading }}
      //- (audio)
      template(v-if="!audioSrc")
        p.text-10.xl_text-12 Audio Missing
      template(v-else)
        //- audio
        audio(ref="audio", :src="audioSrc", @playing="playing = true", @pause="playing = false", @timeupdate="onTimeupdate", @loadedmetadata="onAudioLoaded")
        //- controls
        .flex.items-center
          button.w-30.h-30.flex.items-center.justify-start.mr-30.focus_outline-none.text-black(@click="onPlayPauseBtn", :aria-label="!playing ? 'Play' : 'Pause'")
            svg-play-triangle.h-30.w-30(v-show="!playing")
            svg-pause-bars.h-20.w-20(v-show="playing") ||
          //- scrubber
          .flex-1.cursor-pointer.py-10(@click="progressClick", @mousemove="progressHover", @mouseleave="hoverTime = 0")
            .relative
              progress.block.w-full(min="0", max="1", :value="time / duration")
              //- cursor
              .w-10.h-10.bg-current.pointer-events-none.absolute.left-0.top-0.transform.-translate-y-1x2(:style="{left:`calc(${time / duration} * 100%)`}")
              //- hover cursor
              //- .w-10.h-10.bg-current.pointer-events-none.absolute.left-0.top-0.transform.-translate-y-1x2(:style="{left:`calc(${hoverTime / duration} * 100%)`}", v-show="hoverTime")
          //- time
          .min-w-70.text-10.xl_text-12
            .min-w-40.pl-30.text-right {{ hoverTime ? hhmmss(hoverTime) : !playing && duration !== Infinity ? hhmmss(duration) : hhmmss(time) }}
        //- errors
        p.mt-30.text-10.xl_text-12(v-if="error") {{ error }}

      //- box links
      ul.flex.flex-wrap.mt-60.-mb-8
        li.mr-8.mb-8.md_mr-20
          button.focus_outline-none(@click="onShareBtn", @mouseenter="shareStatus = null")
            btn-boxed {{ shareStatus || 'Share' }}
        li.mr-8.mb-8.md_mr-20(v-for="item in slice.boxLinks")
          flex-link(:link="item.flexLink")
            btn-boxed {{ item.flexLink.customText }}
    //- flex-link.absolute.overlay.flex.items-center.justify-center.px-20.text-20.xl_text-24(:link="slice.flexLink")
      | {{ slice.text }}
      flex-link.ml-50.pointer-events-auto(@click.native.stop, :link="slice.boxLink", v-if="slice.boxLink.url")
        btn-boxed {{ slice.boxLink.text }}
</template>

<script>
export default {
  name: 'SliceAudioPlayer',
  props: {
    slice: Object
  },
  data () {
    return {
      playing: false,
      time: 0,
      duration: 0,
      error: null,
      errorTmOut: null,
      hoverTime: 0,
      shareStatus: null
    }
  },
  computed: {
    audioSrc () {
      return this.slice.audioCustomSrc?.url || this.slice.audioEntry[0]?.audioSourceLink?.url
    }
  },
  methods: {
    hhmmss (seconds) {
      seconds = (!seconds || seconds === Infinity) ? 0 : seconds
      return new Date(seconds * 1000).toISOString().substr(11, 8).replace(/^00:/gm, '')
    },
    play () {
      return this.$refs.audio?.paused && this.$refs.audio.play()
    },
    onPlayPauseBtn () {
      return this.$refs.audio.paused ? this.$refs.audio.play() : this.$refs.audio.pause()
    },
    progressClick (e) {
      const pct = e.offsetX / e.target.offsetWidth
      this.$refs.audio.currentTime = pct * this.duration
      this.play()
      // this.$emit('progressclick', pct)
    },
    progressHover (e) {
      const pct = e.offsetX / e.target.offsetWidth
      requestAnimationFrame(() => {
        this.hoverTime = pct * this.duration
      })
    },

    // audio setup
    onAudioLoaded (e) {
      this.setDuration(e.target)
    },
    onTimeupdate (e) {
      this.time = e.target.currentTime
      // this.clearErrors() // because safari doesn't clear on @playing...
    },
    setDuration (el) {
      if (el.duration && el.duration !== Infinity) {
        this.duration = el.duration
      } else if (el) {
        const url = el.getAttribute('src')
        getDuration(url, duration => {
          this.duration = duration
        })
      }
    },

    // error handling
    onError (e) {
      this.setError(e, 'Audio ' + (e.type || 'Error'))
      // TODO - maybe ignore stalled if loading first track since safari always stalls at end of load...
    },
    onStalled (e) {
      this.setError(e, 'Audio stalled')
    },
    setError (e, msg = 'Audio Error', delay = 3000) {
      console.error(msg, e)
      this.errorTmOut = setTimeout(() => { this.error = msg }, delay)
    },
    clearErrors () {
      clearTimeout(this.errorTmOut)
      this.error = null
    },

    // share
    onShareBtn () {
      if (navigator.share) {
        navigator.share({
          title: document.title,
          text: 'Listen to this :)', // + document.title,
          url: window.location.href
        })
          .then(() => console.log('Successful share'))
          .catch(error => console.log('Error sharing:', error))
      } else if (navigator.clipboard) {
        navigator.clipboard.writeText(window.location.href)
          .then(() => {
            this.shareStatus = 'Link copied!'
          })
          .catch(e => console.error('Clipboard error', e))
      }
    }
  }
}

// get duration since safari always Infinity
const getDuration = function (url, next) {
  const _player = new Audio(url)
  _player.addEventListener('durationchange', function (e) {
    if (this.duration !== Infinity) {
      var duration = this.duration
      _player.remove()
      next(duration)
      console.log('fixed duration')
    }
  }, false)
  _player.load()
  _player.currentTime = 24 * 60 * 60 // fake big time
}
</script>

<style>
/* style progress bar */
progress{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance:none;
  height:1px;

  /* track color (use css bg for Firefox...) */
  background: currentColor;
  &[value]::-webkit-progress-bar{
    background: currentColor;
  }

  /* value color (safari/chrome) */
  &[value]::-webkit-progress-value{
    background:currentColor;
  }
  /* value color (firefox) */
  &[value]::-moz-progress-bar{
    background-color: currentColor;
  }
}
</style>
